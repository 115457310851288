import { FunctionComponent, useState, useEffect } from 'react';
import { Checkbox, DefaultButton, IconButton, MessageBarType, Modal, Pivot, PivotItem, PrimaryButton, Spinner, SpinnerSize, Text, TextField } from '@fluentui/react';
import styles from './FootprintMaintain.module.scss';
import { useBoolean } from '@fluentui/react-hooks';
import DataGridComponent from '../../../../shared/DataGridComponent';
import { IFootprint, footprintTableHeadCells, FootprintMaintainProps, FOOTPRINT_FIELDS, WHEELPOS_FIELDS, pivotItems, wheelPosTableHeadCells, selectWheelPosTableHeadCells, IWheelPositions, IAvailableWheelPositions } from './consts';
import { FormBuilderGroup } from '../../../../shared/FormBuilderComponent';
import apiService from '../../../../api';
import { INotification } from '../../../../models/INotification';
import useNotifications from '../../../../hooks/useNotifications';
import classNames from 'classnames';
import { isNil } from 'lodash';
import whiteSquare from '../../../../assets/icons/whiteSquare.svg';

const FootprintMaintain: FunctionComponent<FootprintMaintainProps> = () => {

  //States
  const [footprintList, setFootprintList] = useState<IFootprint[]>([]);
  const [totalFootprints, setTotalFootprints] = useState(0);
  const [selectedFootprint, setSelectedFootprint] = useState<IFootprint[]>([]);
  const [dataGridStateFootprint, setFootprintDataGridState] = useState<any>();
  const [axleFrontValue, setAxleFrontValue] = useState(false);
  const [axleCenterValue, setAxleCenterValue] = useState(false);
  const [axleRearValue, setAxleRearValue] = useState(false);

  const [wheelPosList, setWheelPosList] = useState<IWheelPositions[]>([]);
  const [totalWheelPositions, setTotalWheelPositions] = useState(0);
  const [selectedWheelPos, setSelectedWheelPos] = useState<IWheelPositions[]>([]);
  const [dataGridStateWheelPositions, setWheelPositionsDataGridState] = useState<any>();

  const [selectWheelPosList, setSelectWheelPosList] = useState<IAvailableWheelPositions[]>([]);
  const [totalSelectWheelPositions, setTotalSelectWheelPositions] = useState(0);
  const [selectedSelectWheelPos, setSelectedSelectWheelPos] = useState<IAvailableWheelPositions[]>([]);
  const [dataGridStateSelectWheelPositions, setSelectWheelPositionsDataGridState] = useState<any>();

  const [axleBase64Format, setAxleBase64Format] = useState<any>();
  const [selectedFile, setSelectedFile] = useState<any | Blob>(null);

  //Notifications
  const { addNotification } = useNotifications();
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });

  //Toggles
  const [isLoadingFootprint, { toggle: toggleLoadingFootprint }] = useBoolean(false);
  const [isLoadingWheelPositions, { toggle: toggleLoadingWheelPositions }] = useBoolean(false);
  const [isLoadingSelectWheelPositions, { toggle: toggleLoadingSelectWheelPositions }] = useBoolean(false);
  const [isLoadingImage, { toggle: toggleLoadingImage }] = useBoolean(false);
  const [isFootprintModalOpen, { toggle: toggleFootprintModalOpen }] = useBoolean(false);
  const [isWheelPosModalOpen, { toggle: toggleWheelPosModalOpen }] = useBoolean(false);
  const [isImageModalOpen, { toggle: toggleImageModalOpen }] = useBoolean(false);
  const [isSelectWheelPosModalOpen, { toggle: toggleSelectWheelPosModalOpen }] = useBoolean(false);

  //Forms
  const footprintFieldsGroup = new FormBuilderGroup(FOOTPRINT_FIELDS);
  const wheelPosFieldsGroup = new FormBuilderGroup(WHEELPOS_FIELDS);

  const [currentTab, setCurrentTab] = useState<any>(
    pivotItems.footprintDetails,
  );

  const handleLinkClick = (item?: PivotItem) => setCurrentTab(item?.props.itemKey);

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  //#region Footprint Functions
  const handleSelectRowFootprint = async (rowsSelected: any) => {
    setSelectedFootprint(rowsSelected);
    footprintFieldsGroup.cleanFormData();

    if (rowsSelected.length == 1) {
      footprintFieldsGroup.setFormValue('footprintIdField', rowsSelected[0].id);
      footprintFieldsGroup.setFormValue('footprintNameField', rowsSelected[0].footprintName);
      footprintFieldsGroup.setFormValue('vehicleTypeField', rowsSelected[0].vehicleType);
      footprintFieldsGroup.setFormValue('wheelsField', rowsSelected[0].wheels);
      footprintFieldsGroup.setFormValue('imageName', rowsSelected[0].imageName);
      setAxleFrontValue(rowsSelected[0].axleFront == 'Y' ? true : false);
      setAxleCenterValue(rowsSelected[0].axleCenter == 'Y' ? true : false);
      setAxleRearValue(rowsSelected[0].axleRear == 'Y' ? true : false);
      fetchWheelPositions(rowsSelected[0].id);
      await loadAxleImage(rowsSelected[0].id);
    }
    else {
      setAxleBase64Format(null);
      setWheelPosList([]);
      wheelPosFieldsGroup.cleanFormData();
      setAxleFrontValue(false);
      setAxleCenterValue(false);
      setAxleRearValue(false);
    }
  };

  const handleFootprintStateChanged = async (state: any) => {
    setFootprintDataGridState(state);
  };

  const onAddFootprintClicked = async () => {
    toggleLoadingFootprint();
    const payload = {
      footprintName: footprintFieldsGroup.getFieldFormValue('footprintNameField'),
      vehicleType: footprintFieldsGroup.getFieldFormValue('vehicleTypeField'),
      wheels: footprintFieldsGroup.getFieldFormValue('wheelsField'),
      axleFront: axleFrontValue == false ? 'N' : 'Y',
      axleCenter: axleCenterValue == false ? 'N' : 'Y',
      axleRear: axleRearValue == false ? 'N' : 'Y',
    };
    try {
      await apiService.management.addFootprint(payload);
      fetchFootprints();
      setNotification({ text: 'Succesfully added footprint', type: MessageBarType.success });
    } catch (e: any) {
      setNotification({ text: 'Error adding footprint', type: MessageBarType.error });
    } finally {
      toggleLoadingFootprint();
    }
  };

  const onUpdateFootprintClicked = async () => {
    toggleLoadingFootprint();
    const payload = {
      id: footprintFieldsGroup.getFieldFormValue('footprintIdField'),
      footprintName: footprintFieldsGroup.getFieldFormValue('footprintNameField'),
      vehicleType: footprintFieldsGroup.getFieldFormValue('vehicleTypeField'),
      wheels: footprintFieldsGroup.getFieldFormValue('wheelsField'),
      axleFront: axleFrontValue == false ? 'N' : 'Y',
      axleCenter: axleCenterValue == false ? 'N' : 'Y',
      axleRear: axleRearValue == false ? 'N' : 'Y',
      imageName: footprintFieldsGroup.getFieldFormValue('imageName'),
    };
    try {
      await apiService.management.updateFootprint(payload);
      fetchFootprints();
      setNotification({ text: 'Succesfully updated footprint', type: MessageBarType.success });
    } catch (e: any) {
      setNotification({ text: 'Error updating footprint', type: MessageBarType.error });
    }
    finally {
      toggleLoadingFootprint();
    }

  };

  const onDeleteFootprintClicked = async () => {
    toggleLoadingFootprint();
    const ids: string[] = [];
    selectedFootprint.forEach(footprint => {
      ids.push(footprint.id);
    });
    try {
      await apiService.management.deleteFootprints(ids);
      toggleFootprintModalOpen();
      fetchFootprints();
      setNotification({ text: 'Succesfully deleted footprint/s', type: MessageBarType.success });
    } catch (e: any) {
      const { response } = e;
      if (!isNil(response.data.state[0].message))
        setNotification({text: `Footprint deleting error: ${response.data.state[0].message}.`, type: MessageBarType.error});
      else
        setNotification({ text: 'Error deleting footprint', type: MessageBarType.error });
    }
    finally {
      toggleLoadingFootprint();
    }
  };

  const fetchFootprints = async () => {
    toggleLoadingFootprint();
    try {
      const payload = {
        filters: {
          searchString: '',
        },
        sortOrder: {
          column: dataGridStateFootprint?.sortOrder?.column,
          order: dataGridStateFootprint?.sortOrder?.order,
        },
      };
      const { data: {data, total}  } = await apiService.management.listFootprint(payload);
      setFootprintList(data);
      setTotalFootprints(total.found);
    }
    finally {
      toggleLoadingFootprint();
    }
  };
  //#endregion

  //#region Upload Image Functions
  const loadAxleImage =  async (footprintId: any) => {
    toggleLoadingImage();
    try {
      const payload = {
        FootprintId: footprintId,
      };
      const { data }: any = await apiService.management.loadImage(payload);
      setAxleBase64Format(data.data);
    } catch (e: any) {
      //setNotification({ text: 'Error loading image', type: MessageBarType.error });
    }
    finally {
      toggleLoadingImage();
    }
  };

  const clearAxleImage =  async () => {
    toggleLoadingImage();
    try {
      const payload = {
        FootprintId: footprintFieldsGroup.getFieldFormValue('footprintIdField'),
      };
      await apiService.management.deleteImage(payload);
      setAxleBase64Format(null);
      setNotification({ text: 'Image has been deleted.', type: MessageBarType.success });
    } catch (e: any) {
      setNotification({ text: 'Error deleting image', type: MessageBarType.error });
    }
    finally {
      toggleLoadingImage();
      await loadAxleImage(footprintFieldsGroup.getFieldFormValue('footprintIdField'));
    }
  };

  const uploadAxleImage = async () => {
    toggleLoadingImage();
    try {
      await apiService.management.uploadImage(
        footprintFieldsGroup.getFieldFormValue('footprintIdField'),
        selectedFile,
      );
      setNotification({ text: 'Image upload is successful.', type: MessageBarType.success });
    } catch (e: any) {
      setNotification({ text: 'Error uploading image', type: MessageBarType.error });
    }
    finally {
      toggleLoadingImage();
      toggleImageModalOpen();
      await loadAxleImage(footprintFieldsGroup.getFieldFormValue('footprintIdField'));
    }
  };

  const changeHandler = async (event: any) => {
    setSelectedFile(event.target.files[0]);
    if(event.target.files[0].name.endsWith('.jpg') || event.target.files[0].name.endsWith('.png') || event.target.files[0].name.endsWith('.gif') || event.target.files[0].name.endsWith('.jfif') || event.target.files[0].name.endsWith('.bmp') || event.target.files[0].name.endsWith('.heic')){
      toggleImageModalOpen();
    }
    else {
      setNotification({ text: 'Please upload a valid image file (JPEG, PNG, GIF, JFIF, BMP, HEIC).', type: MessageBarType.error });
      return false;
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
    toggleImageModalOpen();
  };
  //#endregion

  //#region Wheel Position Functions
  const handleSelectRowWheelPos = async (rowsSelected: any) => {
    setSelectedWheelPos(rowsSelected);
    wheelPosFieldsGroup.cleanFormData();
    if (rowsSelected.length == 1) {
      wheelPosFieldsGroup.setFormValue('wheelPosIdField', rowsSelected[0].id);
      wheelPosFieldsGroup.setFormValue('positionNameField', rowsSelected[0].wheelPositionName);
      wheelPosFieldsGroup.setFormValue('descriptionField', rowsSelected[0].description);
      wheelPosFieldsGroup.setFormValue('seqField', rowsSelected[0].seq);
    }
  };

  const handleWheelPosStateChanged = async (state: any) => {
    setWheelPositionsDataGridState(state);
  };

  const onAddWheelPosClicked = async () => {
    toggleLoadingWheelPositions();
    const payload = {
      wheelPositionName: wheelPosFieldsGroup.getFieldFormValue('positionNameField'),
      description: wheelPosFieldsGroup.getFieldFormValue('descriptionField'),
      seq: wheelPosFieldsGroup.getFieldFormValue('seqField'),
      footprintId: footprintFieldsGroup.getFieldFormValue('footprintIdField'),
    };
    try {
      await apiService.management.addWheelPositions(payload);
      fetchWheelPositions(footprintFieldsGroup.getFieldFormValue('footprintIdField'));
      setNotification({ text: 'Succesfully added wheel position', type: MessageBarType.success });
    } catch (e: any) {
      setNotification({ text: 'Error adding wheel position', type: MessageBarType.error });
    } finally {
      toggleLoadingWheelPositions();
    }
  };

  const onUpdateWheelPosClicked = async () => {
    toggleLoadingWheelPositions();
    const payload = {
      id: wheelPosFieldsGroup.getFieldFormValue('wheelPosIdField'),
      wheelPositionName: wheelPosFieldsGroup.getFieldFormValue('positionNameField'),
      description: wheelPosFieldsGroup.getFieldFormValue('descriptionField'),
      seq: wheelPosFieldsGroup.getFieldFormValue('seqField'),
      footprintId: footprintFieldsGroup.getFieldFormValue('footprintIdField'),
    };
    try {
      await apiService.management.updateWheelPositions(payload);
      fetchWheelPositions(footprintFieldsGroup.getFieldFormValue('footprintIdField'));
      setNotification({ text: 'Succesfully updated wheel position', type: MessageBarType.success });
    } catch (e: any) {
      setNotification({ text: 'Error updating wheel position', type: MessageBarType.error });
    }
    finally {
      toggleLoadingWheelPositions();
    }
  };

  const onDeleteWheelPosClicked = async () => {
    toggleLoadingWheelPositions();
    const ids: string[] = [];
    selectedWheelPos.forEach(wheelPos => {
      ids.push(wheelPos.id);
    });
    const payload = {
      ids: ids,
      footprintId: footprintFieldsGroup.getFieldFormValue('footprintIdField'),
    };
    try {
      await apiService.management.deleteWheelPositions(payload);
      toggleWheelPosModalOpen();
      fetchWheelPositions(footprintFieldsGroup.getFieldFormValue('footprintIdField'));
      setNotification({ text: 'Succesfully deleted wheel position/s', type: MessageBarType.success });
    } catch (e: any) {
      const { response } = e;
      if (!isNil(response.data.state[0].message))
        setNotification({text: `Wheel Position deleting error: ${response.data.state[0].message}.`, type: MessageBarType.error});
      else
        setNotification({ text: 'Error deleting wheel position', type: MessageBarType.error });
    }
    finally {
      toggleLoadingWheelPositions();
    }
  };

  const fetchWheelPositions = async (footprintId: any) => {
    toggleLoadingWheelPositions();
    try {
      const payload = {
        filters: {
          searchString: '',
        },
        sortOrder: {
          column: dataGridStateWheelPositions?.sortOrder?.column,
          order: dataGridStateWheelPositions?.sortOrder?.order,
        },
        footprintId: footprintId,
      };
      const { data } = await apiService.management.getWheelPositions(payload);
      setWheelPosList(data.data);
      setTotalWheelPositions(data.total.found);
    }
    catch (e: any) {
      setNotification({ text: 'Error fetching wheel position/s', type: MessageBarType.error });
    }
    finally {
      toggleLoadingWheelPositions();
    }
  };
  //#endregion

  //#region Select And Add Available Wheel Positions
  const handleSelectRowSelectWheelPos = async (rowsSelected: any) => {
    setSelectedSelectWheelPos(rowsSelected);
  };

  const handleSelectWheelPosStateChanged = async (state: any) => {
    setSelectWheelPositionsDataGridState(state);
  };

  const onSelectAndAddClicked = () => {
    toggleSelectWheelPosModalOpen();
    fetchAvailableWheelPositions();
  };

  const onSelectWheelPosClicked = async () => {
    toggleLoadingSelectWheelPositions();
    const payload = {
      footprintId: footprintFieldsGroup.getFieldFormValue('footprintIdField'),
      data: selectedSelectWheelPos,
    };
    try {
      await apiService.management.addMultipleWheelPositions(payload);
      toggleSelectWheelPosModalOpen();
      fetchWheelPositions(footprintFieldsGroup.getFieldFormValue('footprintIdField'));
      setNotification({ text: 'Succesfully added wheel position/s', type: MessageBarType.success });
    } catch (e: any) {
      setNotification({ text: 'Error adding wheel position/s', type: MessageBarType.error });
    }
    finally {
      toggleLoadingSelectWheelPositions();
    }
  };

  const fetchAvailableWheelPositions = async () => {
    toggleLoadingSelectWheelPositions();
    try {
      const payload = {
        filters: {
          searchString: '',
        },
        sortOrder: {
          column: dataGridStateSelectWheelPositions?.sortOrder?.column,
          order: dataGridStateSelectWheelPositions?.sortOrder?.order,
        },
        footprintId: footprintFieldsGroup.getFieldFormValue('footprintIdField'),
      };
      const { data } = await apiService.management.availableWheelPositions(payload);
      setSelectWheelPosList(data.data);
      setTotalSelectWheelPositions(data.total.found);
    }
    finally {
      toggleLoadingSelectWheelPositions();
    }
  };
  //#endregion

  //Buttons
  const enableFootprintAdd = selectedFootprint.length === 0 && !isNil(footprintFieldsGroup.getFieldFormValue('footprintNameField')) && !isNil(footprintFieldsGroup.getFieldFormValue('vehicleTypeField')) && !isNil(footprintFieldsGroup.getFieldFormValue('wheelsField'));
  const enableFootprintUpdate = selectedFootprint.length === 1;
  const enableFootprintDelete = selectedFootprint.length >= 1;

  const enableWheelPosAdd = selectedFootprint.length === 1 && !isNil(wheelPosFieldsGroup.getFieldFormValue('positionNameField')) && selectedWheelPos.length === 0 ;
  const enableWheelPosUpdate = selectedWheelPos.length === 1;
  const enableWheelPosDelete = selectedWheelPos.length >= 1;

  //Effects
  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  useEffect(() => {
    fetchFootprints();
  }, [dataGridStateFootprint]);

  return (
    <>
      <div className='ms-Grid' dir='ltr'>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm6">
            <div className={styles.gridContainer}>
              <DataGridComponent
                idTable={'makeTable'}
                title={'Available Footprints'}
                headCells={footprintTableHeadCells}
                rowsTable={footprintList}
                totalDataFound={totalFootprints}
                isLoading={isLoadingFootprint}
                enableDeleteOption={false}
                enableMultiSelectRow={false}
                enableEditableRows={false}
                enableCheckBox={true}
                enableAutoControls={false}
                enableSearching={false}
                enablePagination={false}
                enableRowsPerPage={false}
                handleChangeDataGridState={handleFootprintStateChanged}
                handleSelectRow={handleSelectRowFootprint}
              />
            </div>
          </div>

          <div className="ms-Grid-col ms-sm6">
            <div className={classNames('ms-Grid', styles.grid)}>
              <div className="ms-Grid-row">
                <div className={classNames('ms-Grid-col', 'ms-sm12', 'tabulatorWrapper')}>
                  <Pivot
                    aria-label="Select type"
                    selectedKey={currentTab}
                    onLinkClick={handleLinkClick}
                    getTabId={getTabId}
                    linkFormat="tabs"
                  >
                    <PivotItem
                      headerText={pivotItems.footprintDetails}
                      itemKey={pivotItems.footprintDetails}
                      className={styles.inputGroup}
                    >
                      <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6">
                          <div className="ms-Grid-row">
                            <div className={styles.detailsContainer}>
                              <div className="ms-Grid-row">
                                <TextField className={'ms-Grid-col ms-sm12'}
                                  {...footprintFieldsGroup.getFieldForm('footprintNameField')}
                                  required={true}
                                />
                              </div>
                              <div className="ms-Grid-row">
                                <TextField className={'ms-Grid-col ms-sm12'}
                                  {...footprintFieldsGroup.getFieldForm('vehicleTypeField')}
                                  required={true}
                                />
                              </div>
                              <div className="ms-Grid-row">

                                <TextField className={'ms-Grid-col ms-sm12'}
                                  {...footprintFieldsGroup.getFieldForm('wheelsField')}
                                  required={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="ms-Grid-col ms-sm6">
                          <div className="ms-Grid-row">
                            <div className={styles.axleContainer}>
                              <Text variant="medium" block className={styles.highlight}>AXLES</Text>
                              <div className="ms-Grid-row">
                                <div className={styles.axleDetailsContainer}>
                                  <div className="ms-Grid-row">
                                    <Checkbox
                                      className={'ms-Grid-col ms-sm2'}
                                      label={'Front'}
                                      checked={axleFrontValue}
                                      onChange={(_ev, value) => setAxleFrontValue(!!value)}
                                    />
                                  </div>
                                  <div className="ms-Grid-row">
                                    <Checkbox
                                      className={'ms-Grid-col ms-sm2'}
                                      label={'Center'}
                                      checked={axleCenterValue}
                                      onChange={(_ev, value) => setAxleCenterValue(!!value)}
                                    />
                                  </div>
                                  <div className="ms-Grid-row">
                                    <Checkbox
                                      className={'ms-Grid-col ms-sm2'}
                                      label={'Rear'}
                                      checked={axleRearValue}
                                      onChange={(_ev, value) => setAxleRearValue(!!value)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </PivotItem>

                    <PivotItem
                      headerText={pivotItems.wheelPositions}
                      itemKey={pivotItems.wheelPositions}
                      className={styles.inputGroup}
                    >
                      <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                          <div className={styles.gridContainer}>
                            <DataGridComponent
                              idTable={'wheelPosTable'}
                              title={'Available Wheel Positions'}
                              headCells={wheelPosTableHeadCells}
                              rowsTable={wheelPosList}
                              totalDataFound={totalWheelPositions}
                              isLoading={isLoadingWheelPositions}
                              enableDeleteOption={false}
                              enableMultiSelectRow={true}
                              enableEditableRows={false}
                              enableCheckBox={true}
                              enableAutoControls={false}
                              enableSearching={false}
                              enablePagination={false}
                              enableRowsPerPage={false}
                              handleChangeDataGridState={handleWheelPosStateChanged}
                              handleSelectRow={handleSelectRowWheelPos}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="ms-Grid-row">
                        <div className={styles.textFieldContainer}>
                          <TextField className={'ms-Grid-col ms-sm4'}
                            {...wheelPosFieldsGroup.getFieldForm('positionNameField')}
                            disabled={selectedWheelPos.length === 1}
                            required={true}
                            maxLength={3}                          />
                          <TextField className={'ms-Grid-col ms-sm4'}
                            {...wheelPosFieldsGroup.getFieldForm('descriptionField')}
                          />
                          <TextField className={'ms-Grid-col ms-sm4'}
                            {...wheelPosFieldsGroup.getFieldForm('seqField')}
                          />
                        </div>
                      </div>
                    </PivotItem>
                  </Pivot>

                  {currentTab == pivotItems.footprintDetails &&
                  <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
                    <PrimaryButton text='Add' onClick={onAddFootprintClicked} disabled={!enableFootprintAdd} />
                    <PrimaryButton text='Update' onClick={onUpdateFootprintClicked} disabled={!enableFootprintUpdate} />
                    <PrimaryButton text='Delete' onClick={toggleFootprintModalOpen} disabled={!enableFootprintDelete} />
                  </div>
                  }

                  {currentTab == pivotItems.wheelPositions &&
                  <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
                    <PrimaryButton text='Select and Add Wheel Positions' onClick={onSelectAndAddClicked} disabled={!enableFootprintUpdate} />
                    <PrimaryButton text='Add' onClick={onAddWheelPosClicked} disabled={!enableWheelPosAdd} />
                    <PrimaryButton text='Update' onClick={onUpdateWheelPosClicked} disabled={!enableWheelPosUpdate} />
                    <PrimaryButton text='Delete' onClick={toggleWheelPosModalOpen} disabled={!enableWheelPosDelete} />
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        key={'footprintModalID'}
        isOpen={isFootprintModalOpen}
        onDismiss={toggleFootprintModalOpen}
        containerClassName={styles.modalContainer}
      >
        <div className={styles.modalBody}>
          <div className='modalHeader'>
            <Text variant="xLarge">Confirmation</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel='Close popup modal'
              onClick={toggleFootprintModalOpen}
            />
          </div>

          <div className={classNames('ms-Grid-col', styles.modalTextContainer)}>
            <Text variant="medium">Are you sure you want to delete the selected footprint/s?</Text>
          </div>

          <div className={classNames('ms-Grid-col', styles.modalButtonsContainer)}>
            <PrimaryButton
              id='deleteFootprinttModalBtn'
              text="Delete"
              onClick={onDeleteFootprintClicked}
              className={styles.button}
            />
            <DefaultButton
              text="Cancel"
              onClick={toggleFootprintModalOpen}
              className={styles.button}
            />
          </div>
        </div>
      </Modal>

      <Modal
        key={'wheelPosModalID'}
        isOpen={isWheelPosModalOpen}
        onDismiss={toggleWheelPosModalOpen}
        containerClassName={styles.modalContainer}
      >
        <div className={styles.modalBody}>
          <div className='modalHeader'>
            <Text variant="xLarge">Confirmation</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel='Close popup modal'
              onClick={toggleWheelPosModalOpen}
            />
          </div>

          <div className={classNames('ms-Grid-col', styles.modalTextContainer)}>
            <Text variant="medium">Are you sure you want to delete the selected wheel position/s?</Text>
          </div>

          <div className={classNames('ms-Grid-col', styles.modalButtonsContainer)}>
            <PrimaryButton
              id='deleteWheelPosModalBtn'
              text="Delete"
              onClick={onDeleteWheelPosClicked}
              className={styles.button}
            />
            <DefaultButton
              text="Cancel"
              onClick={toggleWheelPosModalOpen}
              className={styles.button}
            />
          </div>
        </div>
      </Modal>

      <Modal
        key={'imageModalID'}
        isOpen={isImageModalOpen}
        onDismiss={toggleImageModalOpen}
        containerClassName={styles.modalContainer}
      >
        <div className={styles.modalBody}>
          <div className='modalHeader'>
            <Text variant="xLarge">Confirmation</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel='Close popup modal'
              onClick={toggleImageModalOpen}
            />
          </div>

          <div className={classNames('ms-Grid-col', styles.modalTextContainer)}>
            <Text variant="medium">Are you sure you want to upload the selected image?</Text>
          </div>

          <div className={classNames('ms-Grid-col', styles.modalButtonsContainer)}>
            <PrimaryButton
              id='uploadModalBtn'
              text="Upload"
              onClick={uploadAxleImage}
              className={styles.button}
            />
            <DefaultButton
              text="Cancel"
              onClick={handleCancel}
              className={styles.button}
            />
          </div>
        </div>
      </Modal>

      <Modal
        key={'selectWheelPosModalID'}
        isOpen={isSelectWheelPosModalOpen}
        onDismiss={toggleSelectWheelPosModalOpen}
        containerClassName={styles.modalContainer}
      >
        <div className={styles.modalBody}>
          <div className='modalHeader'>
            <Text variant="xLarge">Select Wheel Positions</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel='Close popup modal'
              onClick={toggleSelectWheelPosModalOpen}
            />
          </div>

          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              <div className={styles.gridContainer}>
                <DataGridComponent
                  idTable={'selectWheelPosTable'}
                  title={'Available Wheel Positions'}
                  headCells={selectWheelPosTableHeadCells}
                  rowsTable={selectWheelPosList}
                  totalDataFound={totalSelectWheelPositions}
                  isLoading={isLoadingSelectWheelPositions}
                  enableDeleteOption={false}
                  enableMultiSelectRow={true}
                  enableEditableRows={false}
                  enableCheckBox={true}
                  enableAutoControls={false}
                  enableSearching={false}
                  enablePagination={false}
                  enableRowsPerPage={false}
                  handleChangeDataGridState={handleSelectWheelPosStateChanged}
                  handleSelectRow={handleSelectRowSelectWheelPos}
                />
              </div>
            </div>
          </div>

          <div className={classNames('ms-Grid-col', styles.modalButtonsContainer)}>
            <PrimaryButton
              id='selectModalBtn'
              text="Select"
              onClick={onSelectWheelPosClicked}
              className={styles.button}
            />
            <DefaultButton
              text="Cancel"
              onClick={toggleSelectWheelPosModalOpen}
              className={styles.button}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FootprintMaintain;