import { auth_maintain_plants, auth_maintain_makeModel, auth_maintain_product, auth_maintain_typeCodes, auth_maintain_footprints } from '../../consts/programKeys';
import { ITabComponent } from '../../models/ITabComponent';
import MaintainPlantsTab from './tabComponents/MaintainPlants/MaintainPlants';
import MaintainProduct from './tabComponents/MaintainProduct/MaintainProduct';
import MaintainTypeCodes from './tabComponents/MaintainTypeCodes/MaintainTypeCodes';
import MakeModelMaintain from './tabComponents/MakeModelMaintain';
import MaintainFootprintsTab from './tabComponents/FootprintMaintain';

export enum MAINTAIN_TABS {
  MAINTAIN_PRODUCTS = 'maintainProduct',
  MAINTAIN_MAKEMODEL = 'maintainMakeModel',
  MAINTAIN_TYPECODES = 'maintainTypeCodes',
  MAINTAIN_PLANTS = 'maintainPlants',
  MAINTAIN_FOOTPRINTS = 'maintainFootprint',
};

/** Each tab into left menu must have a component */
export const maintainTabs: IMaintainTabs = {
  maintainProduct: {
    id: MAINTAIN_TABS.MAINTAIN_PRODUCTS,
    accessKey: auth_maintain_product,
    name: 'Maintain Product',
    component: MaintainProduct,
    subTabs: null,
  },
  maintainMakeModel: {
    id: MAINTAIN_TABS.MAINTAIN_MAKEMODEL,
    accessKey: auth_maintain_makeModel,
    name: 'Maintain Make/Model',
    component: MakeModelMaintain,
    subTabs: null,
  },
  maintainTypeCodes: {
    id: MAINTAIN_TABS.MAINTAIN_TYPECODES,
    accessKey: auth_maintain_typeCodes,
    name: 'Maintain Type Codes',
    component: MaintainTypeCodes,
    subTabs: null,
  },
  maintainPlants: {
    id: MAINTAIN_TABS.MAINTAIN_PLANTS,
    accessKey: auth_maintain_plants,
    name: 'Maintain Plants',
    component: MaintainPlantsTab,
    subTabs: null,
  },
  maintainFootprints: {
    id: MAINTAIN_TABS.MAINTAIN_FOOTPRINTS,
    accessKey: auth_maintain_plants,
    name: 'Maintain Footprints',
    component: MaintainFootprintsTab,
    subTabs: null,
  },
};

export const getTabById: any = (tabId: string) => Object.values(maintainTabs).filter((tab) => tab.id === tabId)[0];

export interface IMaintainTabs {
  maintainProduct: ITabComponent;
  maintainMakeModel: ITabComponent;
  maintainTypeCodes: ITabComponent;
  maintainPlants: ITabComponent;
  maintainFootprints: ITabComponent;
}