import {
  Checkbox,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown, IconButton, MessageBarType,
  PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import apiService from '../../../../../api';
import useNotifications from '../../../../../hooks/useNotifications';
import LoadingScreen from '../../../../LoadingScreen/LoadingScreen';
import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import { IKeyCharacteristicsProps } from './IKeyCharacteristicsProps';
import { IKeyCharacteristicsState } from './IKeyCharacteristicsState';
import { useBoolean } from '@fluentui/react-hooks';
import styles from './KeyCharacteristics.module.scss';

import { useUserPermissions } from '../../../../../hooks/useUserPermissions';
import { auth_customer_keyCharacteristics} from '../../../../../consts/programKeys';
import { useParams } from 'react-router-dom';

const KeyCharacteristics: FC<IKeyCharacteristicsProps> = () => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_customer_keyCharacteristics);

  const { addNotification } = useNotifications();
  const { id: customerId } = useParams<{ id: string }>();

  const [state, setState] = useState<IKeyCharacteristicsState>({
    loading: false,
    kcLoading: false,
    kcData: null,
    kcLevelsOfService: [],
    kcServiceProviders: [],
  });

  const [errorMessages, setErrorMessages] = useState<any>({});

  const [isEquals, setEquals] = useState(true);
  const [isEditedDialogVisible, { toggle: toggleEditedConfirmation }] = useBoolean(false);

  const fetchKeyCharacteristics = async () => {
    setState(prev => ({ ...prev, kcLoading: true }));
    try {
      const { data }: any = await apiService.customerAPI.getKeyCharacteristics(customerId);
      setState((prev: any) => ({ ...prev, kcData: {...data, mCbCustomerId: data?.mCbCustomerId || customerId} }));
      setErrorMessages({});
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching Key Characteristics error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, kcLoading: false }));
    }
  };

  const fetchServiceProviders = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.tireOrders.getKeyCharacteristicsServiceProviders();
      setState((prev: any) => ({ ...prev, kcServiceProviders: data.kcServiceProviders }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching Service Providers error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchLevelOfService = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.tireOrders.getKeyCharacteristicsLevelsOfService();
      setState((prev: any) => ({ ...prev, kcLevelsOfService: data.kcLevelsOfService }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching Service Providers error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const fetchData = async () => {
    await fetchKeyCharacteristics();
    fetchServiceProviders();
    fetchLevelOfService();
  };

  const handleSave = async () => {
    setEquals(true); 
    setState(prev => ({ ...prev, loading: true }));
    try {
      await apiService.customerAPI.updateKeyCharacteristics(state.kcData);
      fetchData();
      addNotification({
        text: 'Key Characteristics were successfully updated.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          parseErrors(response.data.state);
          return addNotification({
            text: 'Unable to update & validate changes.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Updating plant error: ${response?.data?.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const onChangeKCField = (field: string, value: any) => {
    setState((prev: any) => ({ ...prev, kcData: { ...prev.kcData, [field]: value } }));
    if (value) {
      setErrorMessages((prev: any) => ({ ...prev, [field]: '' }));
    }
    setEquals(false);
  };

  const parseErrors = (errors: any[] = []) => {
    let parsedErrors = {};
    errors.forEach(el => {
      parsedErrors = {
        ...parsedErrors,
        [el.field]: el.message,
      };
    });
    setErrorMessages((prev: any) => ({ ...prev, ...parsedErrors }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.content} id="container">
      { !state.kcLoading ? 
        (state.kcData ? 
          <div className={styles.details}>
            <Text variant="xLarge" className={styles.highlight}>Details</Text>
            <SeparatorGy />
            <div className={styles.detailsWrapper}>
              <div>
                <div className={styles.detailsFields}>
                  <TextField
                    className={styles.detailsField}
                    label="Garage Locations"
                    value={state.kcData.garageLocations}
                    onChange={(_ev: any, value) => onChangeKCField('garageLocations', value)}
                    errorMessage={errorMessages.garageLocations}
                  />
                  <Dropdown
                    className={styles.detailsField}
                    label="Level of Service"
                    options={state.kcLevelsOfService.map((levelOfService) => ({
                      key: levelOfService,
                      text: levelOfService,
                    }))}
                    selectedKey={state.kcData.levelOfService}
                    onChange={(_ev: any, option) => onChangeKCField('levelOfService', option?.key)}
                    errorMessage={errorMessages.levelOfService}
                  />
                  { state.kcData.levelOfService && <IconButton
                    className={styles.cancelButton}
                    iconProps={{ iconName: 'Cancel' }}
                    onClick={() => onChangeKCField('levelOfService', null)}
                  /> }
                </div>
                <div className={styles.detailsFields}>
                  <Dropdown
                    className={styles.detailsField}
                    label="Service Provider"
                    options={state.kcServiceProviders.map((serviceProvider) => ({
                      key: serviceProvider,
                      text: serviceProvider,
                    }))}
                    selectedKey={state.kcData.serviceProvider}
                    onChange={(_ev: any, option) => onChangeKCField('serviceProvider', option?.key)}
                    errorMessage={errorMessages.serviceProvider}
                  />
                  { state.kcData.serviceProvider && <IconButton
                    className={styles.cancelButton}
                    iconProps={{ iconName: 'Cancel' }}
                    onClick={() => onChangeKCField('serviceProvider', null)}
                  /> }
                </div>
                <div className={styles.detailsFields}>
                  <TextField
                    className={styles.detailsField}
                    label="Goodyear Associates"
                    value={state.kcData.goodyearAssociates}
                    onChange={(_ev: any, value) => onChangeKCField('goodyearAssociates', value)}
                    errorMessage={errorMessages.goodyearAssociates}
                  />
                  <TextField
                    className={styles.detailsField}
                    label="Non Goodyear Associates"
                    value={state.kcData.nonGoodyearAssociates}
                    onChange={(_ev: any, value) => onChangeKCField('nonGoodyearAssociates', value)}
                    errorMessage={errorMessages.nonGoodyearAssociates}
                  />
                </div>
                <div className={styles.detailsFields}>
                  <Checkbox
                    className={classNames(styles.detailsField, styles.checkboxField)}
                    label="DBE Required"
                    checked={state.kcData.dbeRequired} 
                    onChange={(_ev: any, value) => onChangeKCField('dbeRequired', value)}
                  />
                </div>
              </div>
              <div>
                <div className={styles.checkboxList}>
                  <Checkbox
                    className={classNames(styles.detailsField, styles.checkboxField)}
                    label="Force Majeure"
                    checked={state.kcData.forceMajeur} 
                    onChange={(_ev: any, value) => onChangeKCField('forceMajeur', value)}
                  />
                  <Checkbox
                    className={classNames(styles.detailsField, styles.checkboxField)}
                    label="Liquidation Damages"
                    checked={state.kcData.liquidationDamages} 
                    onChange={(_ev: any, value) => onChangeKCField('liquidationDamages', value)}
                  />
                  <Checkbox
                    className={classNames(styles.detailsField, styles.checkboxField)}
                    label="RMI"
                    checked={state.kcData.rmi} 
                    onChange={(_ev: any, value) => onChangeKCField('rmi', value)}
                  />
                  <Checkbox
                    className={classNames(styles.detailsField, styles.checkboxField)}
                    label="MO"
                    checked={state.kcData.mo} 
                    onChange={(_ev: any, value) => onChangeKCField('mo', value)}
                  />
                  <Checkbox
                    className={classNames(styles.detailsField, styles.checkboxField)}
                    label="Separate Damages"
                    checked={state.kcData.separateDamages} 
                    onChange={(_ev: any, value) => onChangeKCField('separateDamages', value)}
                  />
                  <Checkbox
                    className={classNames(styles.detailsField, styles.checkboxField)}
                    label="Bonus Billing"
                    checked={state.kcData.bonusBilling} 
                    onChange={(_ev: any, value) => onChangeKCField('bonusBilling', value)}
                  />
                  <Checkbox
                    className={classNames(styles.detailsField, styles.checkboxField)}
                    label="Termination for Convenience"
                    checked={state.kcData.terminationForConvenience} 
                    onChange={(_ev: any, value) => onChangeKCField('terminationForConvenience', value)}
                  />
                  <Checkbox
                    className={classNames(styles.detailsField, styles.checkboxField)}
                    label="Retread Permitted"
                    checked={state.kcData.retreadPermitted} 
                    onChange={(_ev: any, value) => onChangeKCField('retreadPermitted', value)}
                  />
                  <Checkbox
                    className={classNames(styles.detailsField, styles.checkboxField)}
                    label="Traction Required"
                    checked={state.kcData.tractionRequired} 
                    onChange={(_ev: any, value) => onChangeKCField('tractionRequired', value)}
                  />
                  <Checkbox
                    className={classNames(styles.detailsField, styles.checkboxField)}
                    label="Winter Changeover"
                    checked={state.kcData.winterChangeover} 
                    onChange={(_ev: any, value) => onChangeKCField('winterChangeover', value)}
                  />
                  <Checkbox
                    className={classNames(styles.detailsField, styles.checkboxField)}
                    label="Buy America"
                    checked={state.kcData.buyAmerica} 
                    onChange={(_ev: any, value) => onChangeKCField('buyAmerica', value)}
                  />
                </div>
                <Text variant="xLarge" className={styles.highlight}>Comments</Text>
                <SeparatorGy />
                <TextField
                  label="Comments"
                  multiline
                  rows={4}
                  cols={66}
                  value={state.kcData.comments}
                  onChange={(_ev: any, value) => onChangeKCField('comments', value)}
                  errorMessage={errorMessages.comments}
                />
              </div>
            </div>
            <PrimaryButton
              id="saveButton"
              className={styles.actionButton}
              disabled={!userPermissions.isWrite}
              onClick={handleSave} 
              text="Save"
            />
          </div>
          : <Text variant="large">This customer does not have any key characteristics related.</Text>) 
        : ''}
      {(state.loading || state.kcLoading) && <LoadingScreen />}
      {userPermissions.isWrite && <Dialog
        hidden={!isEditedDialogVisible}
        onDismiss={() => { toggleEditedConfirmation(); setEquals(true); }}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: 'You did not save changes. Do you want to save them?',
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="saveChangesButton" onClick={() => { handleSave(); toggleEditedConfirmation(); }} text="Save" />
          <DefaultButton onClick={() => { toggleEditedConfirmation(); setEquals(true); }} text="Cancel" />
        </DialogFooter>
      </Dialog>}
    </div>
  );
};

export default KeyCharacteristics;
