import { IGeneralForm } from './IGeneralForm';

export const typeOfVehicle = {
  series: 'Series',
  singleVehicle: 'Single Vehicle',
};

export const emptyGeneralForm: IGeneralForm = {
  putCOT: true,
  requireUnitConv: false,
  pfx: '',
  vehNoFrom: '',
  vehNoTo: '',
  sfx: '',
  make: '',
  model: '',
  footprintOf: null,
  type: '',
  checkBoxRegDate: false,
  regDate: null,
  whlPos: '',
  year: '',
  torque: '',
  lugs: '',
  axleFrontPSI: '',
  axleCenterPSI: '',
  axleRearPSI: '',
};

export const lugsOptions = ['4', '5', '6', '8', '10', '12'];