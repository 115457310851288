import { FC, useEffect, useState } from 'react';
import useNotifications from '../../../../../../../../../../hooks/useNotifications';
import { IOrderDetailsProps } from './IOrderDetailsProps';
import { IOrderDetailsState } from './IOrderDetailsState';
import apiService from '../../../../../../../../../../api';
import { Text, MessageBarType, TextField, IconButton } from '@fluentui/react';
import { emptyTypeCode } from './consts';
import PrintingModal from '../../../../../../../../../PrintingModal/PrintingModal';
import SeparatorGy from '../../../../../../../../../SeparatorGy/SeparatorGy';
import styles from './OrderDetails.module.scss';
import AutocompleteInput from '../../../../../../../../../../shared/AutocompleteInput';
import LoadingScreen from '../../../../../../../../../LoadingScreen/LoadingScreen';
import { debounce } from 'lodash';
import { useBoolean } from '@fluentui/react-hooks';
import { downloadFile, printingTypes } from '../../../../../../../../../PrintingModal/consts';
import { customerSelector } from '../../../../../../../../../../redux/recordKeepingSlice';
import { useSelector } from 'react-redux';
import TypeCodeModalComponent from '../../../../../../../../../../shared/TypeCodeModalComponent';
import BrandModalComponent from '../../../../../../../../../../shared/BrandSelectionModalComponent';


const OrderDetails: FC<IOrderDetailsProps> = ({
  isFirstLoading,
  wasNotFirstLoading,
  typeCodes,
  setTypeCodes,
  orderToBeAdded, 
  setOrderToBeAdded, 
  orderItemToBeAdded, 
  setOrderItemToBeAdded,
  parsedErrors,
  mode,
}) => {
  const [state, setState] = useState<IOrderDetailsState>({
    loading: false,
    typeCodes: [],
  });

  const [currentTypeCode, setCurrentTypeCode] = useState<any>(emptyTypeCode);
  const [contractBrandPrefix, setContractBrandPrefix] = useState('');

  const [showTypeCodeModal, { toggle: toggleShowTypeCodeModal }] = useBoolean(false);
  const [showBrandModal, { toggle: toggleShowBrandModal }] = useBoolean(false);

  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);

  const { addNotification } = useNotifications();
  const { id: customerId, prefix: brandPrefix, corrAddressCountry: country } = useSelector(customerSelector);


  const fetchTypeCode = async (typeCode?: string) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.typeCodeAPI.getTypeCodeAuthList(
        null,
        {},
        { searchString: typeCode, originalOrRetread: 'R', country: country },
        customerId,
      );
      const items = data.data;
      setState((prev: any) => ({ ...prev, typeCodes: items }));
      setContractBrandPrefix(data.contractBrandPrefix);
      setTypeCodes(items);
      if (isFirstLoading) {
        setCurrentTypeCode(items.find((item: any) => item.typeCode === typeCode));
        wasNotFirstLoading();
      } 
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching type codes error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const selectBrandNumbers = async (brand: any) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.brandAPI.selectBrandNumber(
        brand,
      );
      setOrderItemToBeAdded((prev: any) => ({ 
        ...prev, 
        beginBrandPrefix: contractBrandPrefix, 
        beginBrandNumber: data.brandBegin,
        endBrandPrefix: contractBrandPrefix,
        endBrandNumber: data.brandEnd,
        quantity: data.quantity,
      }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: ` Brand Numbers selecting error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const requestData = {
        id: orderToBeAdded.id,
      };
      
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.tireOrders.printExcelRetread(requestData, []) :
        await apiService.tireOrders.printPdfRetread(requestData, []);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSelectTypeCode = (rowSelected: any) => {
    setOrderToBeAdded((prev: any) => ({ ...prev, typeCode: rowSelected[0].typeCode }));
    fetchTypeCode(rowSelected[0].typeCode);
    setCurrentTypeCode(rowSelected[0]);
    toggleShowTypeCodeModal();
  };

  const setTypeCode = (typeCodeIndex: any) => {
    setOrderToBeAdded((prev: any) => ({ ...prev, typeCode: state.typeCodes[typeCodeIndex].typeCode }));
    setCurrentTypeCode(state.typeCodes[typeCodeIndex]);
  };

  const typeCodeInputText = (typeCode: string) => {
    if (!isFirstLoading && typeCode.length > 0) {
      getTypeCode(typeCode);
    }
  };

  const getTypeCode = debounce(async (typeCode) => {
    fetchTypeCode(typeCode);
  }, 1000);

  const handleSelectBrand = (rowSelected: any) => {
    selectBrandNumbers(rowSelected);
    toggleShowBrandModal();
  };

  const onChangeOrderField = (field: any, value: any, regExp?: RegExp) => {
    setOrderToBeAdded((prev: any) => ({ ...prev, [field]: regExp ? (regExp.test(value) ? value : prev[field]) : value }));
  };

  const onChangeOrderItemField = (id: string, field: string, value: any, regExp?: RegExp) => {
    if (!regExp || (regExp && regExp.test(value))) {
      setOrderItemToBeAdded((prev: any) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const parseErrors = (field: string) => {
    const customError = parsedErrors?.filter((error: { field: string; }) => error.field === field)[0];
    if (customError) {
      return 'Mandatory field is empty';
    }
  };

  useEffect(() => {
    if (isFirstLoading) {
      fetchTypeCode(orderToBeAdded.typeCode);
    } 
  }, [isFirstLoading]);
  
  useEffect(() => {
    setState((prev: any) => ({ ...prev, typeCodes: typeCodes }));
    const typeCodeIndex = typeCodes.findIndex((value: any) => value.typeCode === orderToBeAdded.typeCode);
    typeCodeIndex >= 0 && setCurrentTypeCode(typeCodes[typeCodeIndex]);
  }, []);

  return (
    <>
      <div className={styles.headingBlock}>
        <Text variant="large" className={styles.highlight}>Type code details</Text>
        <SeparatorGy />
        <div>
          <div>
            <Text variant="large" className={styles.highlight}>{currentTypeCode?.productCode || 'N/A'}</Text>
            <Text variant="medium" className={styles.highlight}>Product Code</Text>
          </div>
          <div>
            <Text variant="large" className={styles.highlight}>{currentTypeCode?.tireSize || 'N/A'}</Text>
            <Text variant="medium" className={styles.highlight}>Tire Size</Text>
          </div>
          <div>
            <Text variant="large" className={styles.highlight}>{currentTypeCode?.description || 'N/A'}</Text>
            <Text variant="medium" className={styles.highlight}>Description</Text>
          </div>
          <div>
            <Text variant="large" className={styles.highlight}>{currentTypeCode?.rubberType || 'N/A'}</Text>
            <Text variant="medium" className={styles.highlight}>Type of rubber</Text>
          </div>
        </div>
        <SeparatorGy />
      </div>
      <div className={styles.container}>
        <div>
          <div>
            <AutocompleteInput
              label="Type Code"
              initialValue={orderToBeAdded.typeCode}
              list={state.typeCodes.map((typeCode: any, index: number) => ({
                key: index,
                text: typeCode.typeCode,
              }))}
              emptyExpanded
              required
              chooseCurrentItem={(typeCode: any) => setTypeCode(typeCode)}
              textValue={typeCodeInputText}
              upperCase
              errorMessage={parseErrors('typeCode')}
              disabled={mode === 'view' ? true : false}
            />
            <IconButton
              id='searchPrefix'
              onClick={toggleShowTypeCodeModal}
              iconProps={{ iconName: 'Search' }}
              disabled={mode === 'view' ? true : false}
            />
          </div>
          <div>
            <TextField
              label="QTY"
              value={orderItemToBeAdded.quantity}
              disabled
            />
          </div>
        </div>
        <div>
          <div>
            <TextField
              label="Prefix begin"
              maxLength={4}
              value={orderItemToBeAdded.beginBrandPrefix}
              disabled
            />
          </div>
          <div>
            <TextField
              label="Brand begin"
              maxLength={6}
              value={orderItemToBeAdded.beginBrandNumber}
              disabled
            />
          </div>
          <div>
            <TextField
              label="Suffix begin"
              maxLength={5}
              value={orderItemToBeAdded.beginBrandSuffix}
              disabled
            />
          </div>
          <div className={styles.searchButton}>
            <IconButton
              id='searchPrefix'
              onClick={toggleShowBrandModal}
              iconProps={{ iconName: 'Search' }}
              disabled={mode === 'view' ? true : false}
            />
          </div>
        </div>
        <div>
          <div>
            <TextField
              label="Prefix end"
              maxLength={4}
              value={orderItemToBeAdded.endBrandPrefix}
              disabled
            />
          </div>
          <div>
            <TextField
              label="Brand end"
              maxLength={6}
              value={orderItemToBeAdded.endBrandNumber}
              disabled
            />
          </div>
          <div>
            <TextField
              label="Suffix end"
              maxLength={5}
              value={orderItemToBeAdded.endBrandSuffix}
              disabled
            />
          </div>
        </div>
      </div>
      <TypeCodeModalComponent
        id='wareHouseModal'
        title='Type Codes'
        isOpen={showTypeCodeModal}
        onSubmit={handleSelectTypeCode}
        onDismiss={toggleShowTypeCodeModal}
        successLabel='Select Type Code'
        cancelLabel='Cancel'
        originalOrRetread='R'
        isUserDependent
      />
      <BrandModalComponent
        isOpen={showBrandModal}
        onSubmit={handleSelectBrand}
        onDismiss={toggleShowBrandModal}
        brandPrefix={contractBrandPrefix}
      />
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default OrderDetails;