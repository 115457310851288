//Admin
export const auth_admin_userManagement = 'Admin.UserManagement';
export const auth_admin_taskScheduler = 'Admin.TaskScheduler';

//Billing
export const auth_billing_closeoutBilling = 'Billing.CloseoutBilling';
export const auth_billing_createInvoices = 'Billing.CreateInvoices';
export const auth_billing_postMileageAdjustments = 'Billing.PostMileageAdjustments';
export const auth_billing_regenerateInvoice = 'Billing.RegenerateInvoice';
export const auth_billing_setBillingData = 'Billing.SetBillingData';
export const auth_billing_soldLostTireQuotesBilling = 'Billing.Sold&LostTireQuotes/Billing';
export const auth_billing_viewInvoices = 'Billing.ViewInvoices';
export const auth_billing_viewPendingMileageRun = 'Billing.ViewPendingMileageRun';
export const auth_mileageRun_backout = 'Billing.BackoutMileageRun';

//Customer
export const auth_customer_accounting = 'Customer.Accounting';
export const auth_customer_billTo = 'Customer.BillTo';
export const auth_customer_contacts = 'Customer.Contacts';
export const auth_customer_equipment = 'Customer.Equipment';
export const auth_customer_fleetData = 'Customer.FleetData';
export const auth_customer_general = 'Customer.General';
export const auth_customer_history = 'Customer.History';
export const auth_customer_keyCharacteristics = 'Customer.KeyCharacteristics';
export const auth_customer_owner = 'Customer.Owner';
export const auth_customer_shipTo = 'Customer.ShipTo';
export const auth_customer_searchCustomer = 'Customer.SearchCustomer';

//Maintain
export const auth_maintain_product = 'Maintain.Product';
export const auth_maintain_makeModel = 'Maintain.MakeModel';
export const auth_maintain_typeCodes = 'Maintain.TypeCodes';
export const auth_maintain_plants ='Maintain.Plants';
export const auth_maintain_footprints ='Maintain.Footprints';
export const auth_maintain_oem = 'Maintain.Plants.OEMs';
export const auth_maintain_retread = 'Maintain.Plants.Retread';
export const auth_maintain_whs = 'Maintain.Plants.Warehouse';

//Quote
export const auth_quote_costDetails = 'Quote.CostDetails';
export const auth_quote_forecast = 'Quote.Forecast';
export const auth_quote_history = 'Quote.History';
export const auth_quote_organizeFleet = 'Quote.OrganizeFleet';
export const auth_quote_viewListing = 'Quote.ViewListing';

//Record Keeping - Action on Tires
export const auth_actionOnTires='RK.ActionOnTires';
export const auth_actionOnTires_rebrand = 'RK.ActionOnTires.Rebrand';
export const auth_actionOnTires_reinstate = 'RK.ActionOnTires.Reinstate';
export const auth_actionOnTires_listing = 'RK.ActionOnTires.ViewListing';
export const auth_actionOnTires_viewTireDetails = 'RK.ActionOnTires.ViewTireDetails';
//Record Keeping - Action on Vehicles
export const auth_actionOnVehicles='RK.ActionOnVehicles';
export const auth_actionOnVehicles_registerVehicles = 'RK.ActionOnVehicles.RegisterVehicles';
export const auth_actionOnVehicles_viewVehicleHistory = 'RK.ActionOnVehicles.ViewVehicleHistory';
//Record Keeping - Customer Information
export const auth_customerInformation = 'RK.CustomerInformation';
//Record Keeping - Mileage Run
export const auth_mileageRun='RK.MileageRun';
export const auth_mileageRun_start = 'RK.MileageRun.Start';
export const auth_mileageRun_viewMileageReport = 'RK.MileageRun.MileageRunReport';
//Record Keeping - Spare Stock Posting History
export const auth_spareStockPostingHistory = 'RK.SpareStockPostingHistory';
//Record Keeping - Spare Stock
export const auth_spareStock='RK.SpareStock';
export const auth_spareStock_postSpareStock = 'RK.SpareStock.PostSpareStock';
export const auth_spareStock_viewPostedSpareStock = 'RK.SpareStock.ViewPostedSpareStock';
export const auth_spareStock_massDetails = 'RK.SpareStock.MassDetails';
//Record Keeping - Tire Change
export const auth_tireChange='RK.TireChange';
export const auth_tireChange_postBackdatedChanges = 'RK.TireChange.PostBackdatedChanges';
export const auth_tireChange_postByB73Format = 'RK.TireChange.PostByB73Format';
export const auth_tireChange_postByFootprint = 'RK.TireChange.PostByFootprint';
export const auth_tireChange_viewOpenWheels = 'RK.TireChange.ViewOpenWheels';
export const auth_tireChange_deleteButton = 'RK.TireChange.DeleteButton';
//Record Keeping - Tire Disposition
export const auth_tireDisposition='RK.TireDisposition';
export const auth_tireDisposition_postFlaggedAsDamaged = 'RK.TireDispostion.PostFlaggedAsDamaged';
export const auth_tireDisposition_postOosTires = 'RK.TireDispostion.PostOOSTires';
export const auth_tireDisposition_unflagConsignedTires = 'RK.TireDispostion.UnflagConsignedTires';
export const auth_tireDisposition_transferTires = 'RK.TireDispostion.TransferTires';
//Record Keeping - Vehicle Miles
export const auth_vehicleMiles='RK.VehicleMiles';
export const auth_vehicleMiles_applyMilesToTires = 'RK.VehicleMiles.ApplyMilesToTires';
export const auth_vehicleMiles_postMonthlyMiles = 'RK.VehicleMiles.PostMonthlyMiles';
export const auth_vehicleMiles_postPriorVehicleMiles = 'RK.VehicleMiles.PostPriorVehicleMiles';
export const auth_vehicleMiles_MileageAudit = 'RK.VehicleMiles.MileageAudit';

//Terms
export const auth_terms_billableInspCodes = 'Terms.BillableInspCodes';
export const auth_terms_billingBasis = 'Terms.BillingBasis';
export const auth_terms_contract = 'Terms.Contract';
export const auth_terms_general = 'Terms.General';
export const auth_terms_history = 'Terms.History';
export const auth_terms_misc = 'Terms.Misc';
export const auth_terms_oosAvgs = 'Terms.OOSAvgs';
export const auth_terms_organizeFleet = 'Terms.OrganizeFleet';
export const auth_terms_rateAdjustment = 'Terms.RateAdjustment';
export const auth_terms_treadDepth = 'Terms.TreadDepth';
export const auth_terms_viewListing = 'Terms.ViewListing';

//Tire Ordering - Tire Order Management
export const auth_tireOrder='TO.TireOrderManagement';
export const auth_tireOrder_tireOrders = 'TO.TOM.TireOrders';
export const auth_tireOrder_moveToInTransit = 'TO.TOM.MoveToInTransit';
export const auth_tireOrder_placeOriginal = 'TO.TOM.NewPlaceOriginal';
export const auth_tireOrder_placeRetread = 'TO.TOM.NewPlaceRetread';
//Tire Ordering - Maintain Type Codes
export const auth_mtc='TO.MaintainTypeCodes';
export const auth_mtc_maintainTypeCode = 'TO.MTC.MaintainTypeCodes';
export const auth_mtc_changeTypeCode = 'TO.MTC.ChangeTypeCodes';
//Tire Ordering - Maintain Plants
export const auth_maintainPlants='TO.MaintainPlants';
export const auth_maintainPlants_oem = 'TO.MaintainPlants.OEMs';
export const auth_maintainPlants_retread = 'TO.MaintainPlants.Retread';
export const auth_maintainPlants_whs = 'TO.MaintainPlants.Warehouse';
//Tire Ordering - In-Transit Tires
export const auth_inTransitTires='TO.InTransitTires';
export const auth_inTransitTires_add = 'TO.InTransitTires.Add';
export const auth_inTransitTires_recordShortageInShipment = 'TO.InTransitTires.RecordShortageInShipment';
export const auth_inTransitTires_transfer = 'TO.InTransitTires.Transfer';
export const auth_inTransitTires_updateWithDOT = 'TO.InTransitTires.UpdateWithDOT#';
export const auth_inTransitTires_updateWithTRR = 'TO.InTransitTires.UpdateWithTRR';
export const auth_inTransitTires_viewShortInShipmentTires = 'TO.InTransitTires.ViewShortInShipmentTires';
//Tire Ordering - GBS Order Order Management
export const auth_tireOrder_gbsErrorManagement = 'TO.GBSOrderErrorManagement';


//GENERAL
