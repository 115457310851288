import React, { FC, ReactElement, useState, useEffect } from 'react';
import classNames from 'classnames';
import { IPostFlaggedAsDamagedProps } from './IPostFlaggedAsDamagedProps';
import apiService from '../../../../../../api';
import styles from './PostFlaggedAsDamaged.module.scss';
import { Text, IColumn, IconButton, IDropdownOption, MessageBarType, TextField, MaskedTextField, Icon, PrimaryButton, DefaultButton, Dropdown, Dialog, DialogFooter, DialogType, MessageBar } from '@fluentui/react';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../../Pagination/Pagination';
import { emptyPostFlaggedTire, inspectionCodesColumns, columns } from './consts';
import { pageSizes } from '../../../../../../consts/recordKeeping';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';
import { useBoolean } from '@fluentui/react-hooks';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import { IPostFlaggedAsDamagedState } from './IPostFlaggedAsDamagedState';
import { get, isEqual, isNil } from 'lodash';
import moment from 'moment';
import useNotifications from '../../../../../../hooks/useNotifications';
import { useDispatch, useSelector } from 'react-redux';
import { customerSelector, locationSelector, setTireForReinstate } from '../../../../../../redux/recordKeepingSlice';
import { IPostFlaggedTire } from './IPostFlaggedTire';
import AutocompleteInput from '../../../../../../shared/AutocompleteInput';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import SelectingModal from '../../../../../SelectingModal/SelectingModal';
import ViewTireDetailsModal from '../../../../ViewTireDetailsModal/ViewTireDetailsModal';
import ErrorsModal from '../../../../../ErrorsModal/ErrorsModal';
import { sortOrder } from '../../../../../../consts/sortOrder';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_tireDisposition_postFlaggedAsDamaged } from '../../../../../../consts/programKeys';
import useKeyPress from '../../../../../../hooks/useKeyPress/useKeyPress';

const PostFlaggedAsDamaged: FC<IPostFlaggedAsDamagedProps> = (): ReactElement => {
  const [state, setState] = useState<IPostFlaggedAsDamagedState>({
    loading: false,
    items: [],
    selectedItems: [],
    foundCount: 0,
  });

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) =>
      setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });

  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [showErrorsModal, { toggle: toggleShowErrorsModal }] = useBoolean(false);
  const [detailsVisible, { toggle: toggleDetailsVisible }] = useBoolean(false);
  const [tireToBeAdded, setTireToBeAdded] = useState<any>(emptyPostFlaggedTire);
  const [showAModal, { toggle: toggleShowAModal }] = useBoolean(false);
  const [untouchedItems, setUntouchedItems] = useState<Array<any>>([]);
  const [parsedErrors, setParsedErrors] = useState<any>([]);
  const [inspectionCodes, setInspectionCodes] = useState<Array<any>>([]);
  const [parsedAddingErrors, setParsedAddingErrors] = useState<any>([]);
  const [isTDRequired, setIsTDRequired] = useState<boolean>(false);

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_tireDisposition_postFlaggedAsDamaged);

  const mountingProcess = async () => {
    await getInspectionCodes();
  };

  useEffect(() => {
    mountingProcess();
  }, []);

  const dispatch = useDispatch();
  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);

  const [columnsState, setColumnsState] = useState<Array<any>>(columns);

  const getSortOrder = () => {
    const column = columnsState.find(({ isSorted }) => isSorted);
    if (isNil(column))
      return {
        column: 'createdOn',
        order: sortOrder.ASC,
      };
    const { fieldName, isSortedDescending } = column;
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const openViewTireDetailsWindow = (item: any) => {
    const { consignedTireId, prefix, brandNumber, suffix } = item;
    dispatch(setTireForReinstate({ tireId: consignedTireId, pfx: prefix, bno: brandNumber, sfx: suffix }));
    toggleDetailsVisible();
  };

  const fetchFlaggedAsDamaged = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.postFlaggedAsDamaged.get(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
      );
      const foundCount = data.total.found;
      const items = data.data.map(
        ({ oosDate, ...others }: any) => ({ oosDate: moment(oosDate).format('MM/DD/YYYY'), ...others }),
      );
      setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      addNotification({
        text: `Post Flagged as Damaged fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
        customerId,
        locationId,
      };
     
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.postFlaggedAsDamaged.printExcel(requestData) :
        await apiService.postFlaggedAsDamaged.printPdf(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const onChangeCountOnPage = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.postFlaggedAsDamaged.delete(state.selectedItems);
      addNotification({
        text: 'Selected tire(s) were successfully deleted.',
        type: MessageBarType.success,
      });
      if (paginationProps.current === 1) {
        await fetchFlaggedAsDamaged();
      } else {
        await paginationProps.onChangePage(1);
      }
    } catch (e: any) {
      const { response } = e;
      setState((prev: any) => ({ ...prev, loading: false }));
      addNotification({
        text: `Post Flagged As Damaged deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    }
  };

  const handleSave = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.postFlaggedAsDamaged.update(state.items, customerId, locationId);
      setUntouchedItems(state.items);
      setParsedErrors([]);
      addNotification({
        text: 'Post Flagged tires were successfully saved.',
        type: MessageBarType.success,
      });
      if (paginationProps.current === 1) {
        await fetchFlaggedAsDamaged();
      } else {
        await paginationProps.onChangePage(1);
      }
    } catch (e: any) {
      const { response } = e;
      setState((prev: any) => ({ ...prev, loading: false }));
      switch (response.status) {
        case 400:
          setParsedErrors(response.data.state);
          return addNotification({
            text: 'Saving tires error.',
            type: MessageBarType.error,
          });
        default:
          return addNotification({
            text: response.data.message,
            type: MessageBarType.error,
          });
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.postFlaggedAsDamaged.submit(state.items, customerId, locationId);
      setUntouchedItems(state.items);
      setParsedErrors([]);
      addNotification({
        text: 'Post Flagged tires were successfully submitted.',
        type: MessageBarType.success,
      });
      if (paginationProps.current === 1) {
        await fetchFlaggedAsDamaged();
      } else {
        await paginationProps.onChangePage(1);
      }
    } catch (e: any) {
      const { response } = e;
      setState((prev: any) => ({ ...prev, loading: false }));
      switch (response.status) {
        case 400:
          if (response.data.state) {
            setParsedErrors(response.data.state);
            return addNotification({
              text: 'Submitting tires error.',
              type: MessageBarType.error,
            });
          }
          return addNotification({
            text: response.data.desc.message,
            type: MessageBarType.error,
          });
        default:
          return addNotification({
            text: response?.data?.message || response?.data?.desc?.message,
            type: MessageBarType.error,
          });
      }
    }
  };

  const handleSelect = (e: any, itemId: any) => {
    if (e.target.checked) {
      setState((prev: any) => ({ ...prev, selectedItems: [...state.selectedItems, itemId] }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItems: state.selectedItems.filter(row => row !== itemId) }));
    }
  };

  const handleSelectAll = (e: any, items: Array<any>) => {
    e.target.checked ? setState((prev: any) => ({ ...prev, selectedItems: items })) : setState((prev: any) => ({ ...prev, selectedItems: [] }));
  };

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = columnsState.map(el => ({ ...el }));
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        if (!currColumn.isSorted) currColumn.isSortedDescending = true;
        if (currColumn.isSortedDescending && currColumn.isSorted) {
          currColumn.isSorted = false;
          currColumn.isSortedDescending = true;
        }
        else {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        }
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const isItemUntouched = (itemToCheck: any) => {
    const untouchedItem = untouchedItems.find(({ id }: any) => id === itemToCheck.id);
    if (isNil(untouchedItem))
      return false;
    return isEqual(itemToCheck, untouchedItem);
  };

  const handleAddTire: any = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.postFlaggedAsDamaged.add(
        {
          ...tireToBeAdded, a: inspectionCodes.find(item => item.id == tireToBeAdded.a).inspCode,
        },
        customerId,
        locationId,
      );
      setParsedAddingErrors([]);
      setTireToBeAdded((tireToBeAdded: any) => ({ ...tireToBeAdded, brandNumber: '', suffix: '' }));
      await fetchFlaggedAsDamaged();
      addNotification({
        text: 'Tire was successfully added.',
        type: MessageBarType.success,
      });
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          setParsedAddingErrors(response.data.state);
          if (response.data.state) {
            return addNotification({
              text: `Adding form error. ${response.data.state[0].message}`,
              type: MessageBarType.error,
            });
          } else {
            return addNotification({
              text: `Adding form error. ${response.data.desc.message}`,
              type: MessageBarType.error,
            });
          }
        case 499:
          if (response.data.state) {
            return addNotification({
              text: `Adding form error. ${response.data.state[0].message}`,
              type: MessageBarType.error,
            });
          }
          setTireToBeAdded((tireToBeAdded: any) => ({ ...tireToBeAdded, brandNumber: '', suffix: '' }));
          return addNotification({
            text: response.data.message,
            type: MessageBarType.error,
          });
        default:
          return addNotification({
            text: response.data.message,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  useKeyPress({
    handleAdd: handleAddTire,
  });

  const setA = (a: any) => {
    setTireToBeAdded((prev: any) => ({ ...prev, a }));
    toggleShowAModal();
  };

  const parseErrors = (id: string, field: string) => {
    const customError = parsedErrors?.filter((error: { id: string; field: string; }) => error.id === id && error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  const getInspectionCodes = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await apiService.getInspectionCodesByCustomer(customerId, '');
      setInspectionCodes(data);
      data.find((item: any) => item.f32ndCostUsed == 'Y') ? setIsTDRequired(true) : setIsTDRequired(false);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching inspection codes error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const highlightRowIfError = (item: any) => {
    if (parsedErrors.length > 0) {
      return parsedErrors.filter((error: { id: any; }) => error.id === item.id).length > 0;
    }
  };

  const setTireToBeEdited = (id: string, field: string, value: any) => {
    setState((prev: any) => ({
      ...prev,
      items: prev.items.map((item: IPostFlaggedTire) => item.id === id ? { ...item, [field]: value } : item),
    }));
  };

  const parseAddingErors = (field: string) => {
    const customError = parsedAddingErrors?.filter((error: { field: string; }) => error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  const onChangeField = (field: any, value: any, regExp?: RegExp) =>
    setTireToBeAdded((prev: any) => ({ ...prev, [field]: regExp ? (regExp.test(value) ? value : prev[field]) : value }));

  const aInputText = (a: string) => {
    const code = inspectionCodes.find((item: any) => item.inspCode == a);
    if (code) {
      onChangeField('a', code.id);
    } else {
      onChangeField('a', null);
    }
  };

  useEffect(() => {
    fetchFlaggedAsDamaged();
  }, [
    paginationProps.current,
    countOnPage,
    columnsState,
    customerId,
    locationId,
  ]);

  return (
    <>
      <div className="ms-Grid">
        <div className={classNames('ms-Grid-row', styles.headingBlock)}>
          <div className={classNames(styles.tiresAddingBlock, 'ms-Grid-col', 'ms-sm12')}>
            <div>
              <div>
                <MaskedTextField
                  mask="99/99/9999"
                  label="Enter OOS Date"
                  value={tireToBeAdded.oosDate}
                  onChange={(e, oosDate) => onChangeField('oosDate', oosDate)}
                  onRenderPrefix={() => <Icon iconName="Calendar" />}
                  errorMessage={parseAddingErors('oosDate')}
                  required
                />
              </div>
              <div>
                <TextField
                  label="Doc/Claim #"
                  prefix="#"
                  value={tireToBeAdded.docNumber}
                  onChange={(e, docNumber) => onChangeField('docNumber', docNumber, /^[a-zA-Z0-9]*$/)}
                  errorMessage={parseAddingErors('docNumber')}
                  required
                />
              </div>
              <div>
                <div>
                  <TextField
                    label="Prefix"
                    maxLength={4}
                    value={tireToBeAdded.prefix}
                    onChange={(e, prefix) => onChangeField('prefix', prefix?.toUpperCase(), /^[a-zA-Z0-9]*$/)}
                    errorMessage={parseAddingErors('prefix')}
                    required
                  />
                </div>
                <div>
                  <TextField
                    label="Brand Number"
                    maxLength={6}
                    value={tireToBeAdded.brandNumber}
                    onChange={(e, brandNumber) => onChangeField('brandNumber', brandNumber, /^[0-9]*$/)}
                    errorMessage={parseAddingErors('brandNumber')}
                    required
                  />
                </div>
                <div>
                  <TextField
                    label="Suffix"
                    maxLength={5}
                    value={tireToBeAdded.suffix}
                    onChange={(e, suffix) => onChangeField('suffix', suffix?.toUpperCase(), /^[A-Z0-9]*$/)}
                    errorMessage={parseAddingErors('suffix')}
                  />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>
                  <AutocompleteInput
                    label="A"
                    value={get(tireToBeAdded, 'a', null)}
                    list={inspectionCodes.map((el: any) => ({ key: el.id, text: el.inspCode }))}
                    chooseCurrentItem={(a: any) => onChangeField('a', a)}
                    required
                    emptyExpanded
                    textValue={aInputText}
                  />
                  <IconButton
                    iconProps={{ iconName: 'Search' }}
                    onClick={toggleShowAModal}
                  />
                </div>
              </div>
              <div>
                <div>
                  <TextField
                    label="TD"
                    suffix="/32"
                    value={tireToBeAdded.treadDepth}
                    onChange={(e, treadDepth) => onChangeField('treadDepth', treadDepth)}
                    errorMessage={parseAddingErors('treadDepth')}
                    max={32}
                    min={0}
                    required={isTDRequired}
                  />
                </div>
              </div>
              <div>
                <div>
                  <PrimaryButton
                    id="addButton"
                    disabled={
                      !tireToBeAdded.oosDate ||
                      !tireToBeAdded.docNumber ||
                      !tireToBeAdded.prefix ||
                      !tireToBeAdded.brandNumber ||
                      !tireToBeAdded.a ||
                      !userPermissions.isWrite
                    }
                    onClick={handleAddTire}
                    text="Add"
                  />
                </div>
                <div>
                  <DefaultButton
                    onClick={() => setTireToBeAdded(emptyPostFlaggedTire)}
                    text="Clear"
                    disabled={!userPermissions.isWrite}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <div className={styles.tableHeading}>
              <div>
                <Text variant="xLarge" className={styles.highlight}>Disposed Tires</Text>
                <SeparatorGy vertical />
                <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
              </div>
              <div>
                <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                <Dropdown
                  options={pageSizes.map(pageSize => ({
                    key: pageSize,
                    text: pageSize.toString(),
                  }))}
                  defaultSelectedKey={pageSizes[0]}
                  selectedKey={countOnPage?.key}
                  onChange={onChangeCountOnPage}
                />
                <SeparatorGy vertical />
                <Text variant="large" className={styles.highlight}>{state.selectedItems.length} items selected</Text>
                <SeparatorGy vertical />
                <IconButton
                  id="toggleDeletingConfirmationButton"
                  disabled={!state.selectedItems.length || !userPermissions.isWrite}
                  iconProps={{ iconName: 'Delete' }}
                  onClick={toggleDeletingConfirmation}
                />
              </div>
            </div>
            <div className={styles['table-wrapper']}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th></th>
                    {
                      columnsState.map(item => (
                        <th
                          key={item.name}
                          className={
                            item.isSorted && item.isSortedDescending ?
                              styles.descending :
                              item.isSorted && !item.isSortedDescending ? styles.ascending : undefined
                          }
                          onClick={() => onColumnClick(item)}
                        >
                          {item.name}
                        </th>
                      ))
                    }
                    <th></th>
                    <th>
                      <div className={styles.round}>
                        <input
                          type="checkbox"
                          id="all"
                          checked={state.items.length !== 0 && (state.selectedItems.length === +countOnPage.key || state.selectedItems.length === state.items.length)}
                          onChange={(e) => handleSelectAll(e, state.items.map(({ id }) => id))}
                        />
                        <label htmlFor="all"></label>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    state.items.map(item => (
                      <tr key={item.id}
                        className={
                          classNames(
                            styles.trBasic,
                            {
                              [styles.trSelected]: state.selectedItems.includes(item.id),
                              [styles.trError]: highlightRowIfError(item),
                            },
                          )
                        }>
                        <td>
                          <IconButton
                            id="viewDetails"
                            iconProps={{ iconName: 'View' }}
                            className={classNames(styles.viewButton)}
                            onClick={() => openViewTireDetailsWindow(item)}
                          />
                        </td>
                        <td>{item.prefix}</td>
                        <td>
                          {item.brandNumber}
                          <div className={styles.errorMessage}>
                            {parseErrors(item.id, 'brandNumber')}
                          </div>
                        </td>
                        <td>{item.suffix}</td>
                        <td>
                          <TextField
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.a}
                            onChange={(e, a) => setTireToBeEdited(item.id, 'a', a)}
                            errorMessage={parseErrors(item.id, 'a')}
                          />
                        </td>
                        <td>
                          <TextField
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.treadDepth}
                            onChange={(e, treadDepth) => setTireToBeEdited(item.id, 'treadDepth', treadDepth)}
                            errorMessage={parseErrors(item.id, 'treadDepth')}
                          />
                        </td>
                        <td>
                          <TextField
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.oosDoc}
                            onChange={(e, oosDoc) => setTireToBeEdited(item.id, 'oosDoc', oosDoc)}
                            errorMessage={parseErrors(item.id, 'oosDoc')}
                          />
                        </td>
                        <td>
                          <MaskedTextField
                            styles={{ fieldGroup: { border: '1px solid transparent' } }}
                            value={item.oosDate}
                            mask="99/99/9999"
                            onChange={(e, oosDate) => setTireToBeEdited(item.id, 'oosDate', oosDate)}
                            errorMessage={parseErrors(item.id, 'oosDate')}
                          />
                        </td>
                        <td>{item.plant}</td>
                        <td>{item.tireClass}</td>
                        <td>{item.typeCode}</td>
                        <td>
                          {item.currentVehicle}
                          <div className={styles.errorMessage}>
                            {parseErrors(item.id, 'currentVehicle')}
                          </div>
                        </td>
                        <td>{item.currentPosition}</td>
                        <td>{item.currentOn && moment(item.currentOn).format('MM/DD/YYYY')}</td>
                        <td>{item.previousVehicle}</td>
                        <td>{item.previousPosition}</td>
                        <td>{item.previousOff && moment(item.previousOff).format('MM/DD/YYYY')}</td>
                        <td>{item.miles}</td>
                        <td>
                          {
                            isItemUntouched(item) ?
                              <MessageBar
                                messageBarType={MessageBarType.success}
                                isMultiline={false}
                              >
                                Submitted
                              </MessageBar> :
                              <MessageBar
                                messageBarType={MessageBarType.warning}
                                isMultiline={false}
                              >
                                Unsubmitted
                              </MessageBar>
                          }
                        </td>
                        <td>
                          <div className={styles.round}>
                            <input type="checkbox" id={item.id} checked={state.selectedItems.includes(item.id)} onChange={(e) => handleSelect(e, item.id)} />
                            <label htmlFor={item.id}></label>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <SeparatorGy />
            <Pagination {...paginationProps} />
          </div>
        </div>
        <div className={classNames('ms-Grid-row', styles.buttonsWrapper)}>
          <DefaultButton
            id='printExportBtn'
            onClick={toggleShowPrintExport}
            text="Print/Export"
          />
          <DefaultButton
            onClick={toggleShowErrorsModal}
            text="View Errors"
          />
          <PrimaryButton
            id='saveButton'
            onClick={handleSave}
            disabled={!userPermissions.isWrite}
            text="Save"
          />
          <PrimaryButton
            id='submitButton'
            onClick={handleSubmit}
            disabled={!userPermissions.isWrite}
            text="Submit"
          />
        </div>
      </div>
      <ErrorsModal
        isModalOpen={showErrorsModal}
        hideModal={toggleShowErrorsModal}
        fetchErrorsService={apiService.postFlaggedAsDamaged.getErrors}
      />
      {detailsVisible && <ViewTireDetailsModal isOpened={detailsVisible} onDismiss={toggleDetailsVisible} />}
      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Are you sure you want to delete ${state.selectedItems.length} items?`,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      <SelectingModal
        isOpen={showAModal}
        title="Available Inspection Codes"
        selectingList={inspectionCodes.map(code => ({ id: code.id, billable: code.billable, inspCode: code.inspCode, description: code.description, comments: code.comments }))}
        onDismiss={toggleShowAModal}
        onSubmit={setA}
        columns={inspectionCodesColumns}
        preselectedKey={get(tireToBeAdded, 'a', null)}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default PostFlaggedAsDamaged;

