import { IFiltersState } from './IFiltersState';

export const tableColumns = [
  {
    key: 'column0',
    name: 'Vehicle Number',
    fieldName: 'vehNo',
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: 'column1',
    name: 'Make',
    fieldName: 'make',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column2',
    name: 'Model',
    fieldName: 'model',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column3',
    name: 'Wheel Position',
    fieldName: 'whlPos',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column4',
    name: 'Year',
    fieldName: 'year',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column5',
    name: 'Status',
    fieldName: 'status',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column6',
    name: 'As Of',
    fieldName: 'asOf',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column7',
    name: 'Comments',
    fieldName: 'comments',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column8',
    name: 'Torque Per Vehicle',
    fieldName: 'torquePerVehicle',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column9',
    name: '# of Lugs',
    fieldName: 'lugs',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column10',
    name: 'Front Axle PSI',
    fieldName: 'axleFrontPSI',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column11',
    name: 'Center Axle PSI',
    fieldName: 'axleCenterPSI',
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: 'column12',
    name: 'Rear Axle PSI',
    fieldName: 'axleRearPSI',
    isSorted: false,
    isSortedDescending: false,
  },
];

export const emptyFilters: IFiltersState = {
  showUnregistered: false,
  whlPos: null,
  vehNoTo: '',
  vehNoFrom: '',
  status: null,
  changeDateFrom: null,
  changeDateTo: null,
  asOfFrom: null,
  asOfTo: null,
  model: [],
  make: [],
};

export const accordions: any = {
  comments: 'comments',
  general: 'general',
  renumber: 'renumber',
  relocate: 'relocate',
};
