import { FC, FormEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useBoolean } from '@fluentui/react-hooks';
import { get, isNil } from 'lodash';
import {
  DefaultButton,
  Dropdown,
  IColumn,
  IDropdownOption,
  Label,
  MessageBarType,
  PrimaryButton,
  Text,
  TextField,
  IIconProps,
} from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';

import apiService from '../../../../../../api';
import useNotifications from '../../../../../../hooks/useNotifications';
import { sortOrder } from '../../../../../../consts/sortOrder';
import { customerInformationSelector, customerSelector, locationSelector, setCustomerInformation } from '../../../../../../redux/recordKeepingSlice';

import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../../../Pagination/Pagination';
import Accordion from '../../../../../Accordion/Accordion';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';

import FilterModalWindow from './FilterModalWindow/FilterModalWindow';
import GeneralRegisterVehicle from './accordionComponents/GeneralRegisterVehicle/GeneralRegisterVehicle';
import RenumberRegisterVehicle from './accordionComponents/RenumberRegisterVehicle/RenumberRegisterVehicle';
import RelocateRegisterVehicle from './accordionComponents/RelocateRegisterVehicle/RelocateRegisterVehicle';

import { IFiltersState } from './IFiltersState';
import { IRegisterVehiclesProps } from './IRegisterVehiclesProps';
import { IRegisterVehiclesState } from './IRegisterVehiclesState';
import { tableColumns, emptyFilters, accordions } from './consts';
import { pageSizes } from '../../../../../../consts/recordKeeping';

import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_actionOnVehicles_registerVehicles} from '../../../../../../consts/programKeys';

import styles from './RegisterVehicles.module.scss';
import { transformDate } from '../../../../../../shared/transformDate';

const filterIcon : IIconProps = {iconName: 'Equalizer'};

const RegisterVehicles: FC<IRegisterVehiclesProps> = () => {

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_actionOnVehicles_registerVehicles);
  const dispatch = useDispatch();
  const { addNotification } = useNotifications();
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);
  const { billBy } = useSelector(customerInformationSelector);

  const [state, setState] = useState<IRegisterVehiclesState>({
    items: [],
    selectedItem: null,
    loading: false,
    total: 0,
  });

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });

  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(tableColumns);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filtersState, setFiltersState] = useState<IFiltersState>(emptyFilters);
  const [commentsState, setCommentsState] = useState<string>('');
  const [openedAccordion, setOpenedAccordion] = useState<any>(null);
  const [levelOfService, setLevelOfService] = useState<string>('');

  const fetchCustomerData = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data }: any = await apiService.getCustomerInformation(customerId);
      dispatch(setCustomerInformation(data.data));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Customer data fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const setCommentsValue = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | undefined) => {
    setCommentsState(value || '');
  };

  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const fetch = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.registerVehicles.get(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        {
          ...filtersState,
          asOfFrom: filtersState.asOfFrom && transformDate(moment(filtersState.asOfFrom).format('MM/DD/YYYY')),
          asOfTo: filtersState.asOfTo && transformDate(moment(filtersState.asOfTo).format('MM/DD/YYYY')),
          changeDateFrom: filtersState.changeDateFrom && transformDate(moment(filtersState.changeDateFrom).format('MM/DD/YYYY')),
          changeDateTo: filtersState.changeDateTo && transformDate(moment(filtersState.changeDateTo).format('MM/DD/YYYY')),
        },
        sortOrder,
        customerId,
        billBy == 'Location' ? locationId : null,
      );

      if (get(data, 'desc.code', 200) !== 200) {
        addNotification({
          text: `Fetching vehicles error: ${get(data, 'desc.message', '')}`,
          type: MessageBarType.error,
        });
      } else {
        const foundCount = data.data.total.found;
        const items = data.data.data.map((vehicle: any) => ({
          ...vehicle,
          comments: isNil(vehicle.comments) ? '' : vehicle.comments,
          requireUnitConv: vehicle.ruCov,
        }));
        setState((prev: any) => ({ ...prev, items, selectedItem: null, total: data.data.total.found }));
        setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
      }

      try {
        const { data }: any = await apiService.customerAPI.getKeyCharacteristics(customerId);
        setLevelOfService(data.levelOfService);
      }
      catch (e: any) {
        const { response } = e;
        addNotification({
          text: `Fetching level of service error: ${response.data.message}`,
          type: MessageBarType.error,
        });
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching vehicles error: ${get(response, 'data.message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const onSaveComments = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.registerVehicles.saveComments(state.selectedItem, commentsState);
      addNotification({
        text: `Comments were successfully updated: ${data.message}`,
        type: MessageBarType.success,
      });
      fetch();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Updating comments error: ${response.data.message}`,
        type: MessageBarType.error,
      });
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        pagination: { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        filters: {
          ...filtersState,
          asOfFrom: filtersState.asOfFrom && transformDate(moment(filtersState.asOfFrom).format('MM/DD/YYYY')),
          asOfTo: filtersState.asOfTo && transformDate(moment(filtersState.asOfTo).format('MM/DD/YYYY')),
          changeDateFrom: filtersState.changeDateFrom && transformDate(moment(filtersState.changeDateFrom).format('MM/DD/YYYY')),
          changeDateTo: filtersState.changeDateTo && transformDate(moment(filtersState.changeDateTo).format('MM/DD/YYYY')),
        },
        sortOrder,
        customerId,
        locationId: billBy == 'Location' ? locationId : null,
      };
      const { data }: any = printingType === printingTypes.excel ?
        await apiService.registerVehicles.printExcel(requestData, []) :
        await apiService.registerVehicles.printPdf(requestData, []);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Printing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const getVehicleById = (idToFind: any) => state.items.find(({ id }: any) => id === idToFind);

  const handleSelect = (e: any, itemId: any) => {
    if (e.target.checked) {
      setState((prev: any) => ({ ...prev, selectedItem: getVehicleById(itemId).id }));
    } else {
      setState((prev: any) => ({ ...prev, selectedItem: null }));
    }
  };

  const handleUpdateFilters = (updatedFilters: IFiltersState) => {
    setPaginationProps(prev => ({ ...prev, current: 1 }));
    setFiltersState(updatedFilters);
  };

  const handleAccordionClick = (clickedAccordionHeader: any) => {
    setOpenedAccordion(clickedAccordionHeader === openedAccordion ? null : clickedAccordionHeader);
  };

  useEffect(() => {
    fetch();
  }, [
    paginationProps.current,
    columnsState,
    countOnPage,
    filtersState,
    customerId,
    locationId,
  ]);

  useEffect(() => {
    if(!billBy) {
      fetchCustomerData();
    }
  }, [billBy]);
  useEffect(() => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
  }, [customerId]);

  useEffect(() => {
    if (openedAccordion !== accordions.general)
      setOpenedAccordion(accordions.general);
    setCommentsState(state.selectedItem && getVehicleById(state.selectedItem).comments ? getVehicleById(state.selectedItem).comments : '');
  }, [
    state.selectedItem,
  ]);

  return (
    <>
      <div className="ms-Grid">
        <div className={classNames('ms-Grid-row', styles.mainRow)}>
          <div className={classNames('ms-Grid-col', 'ms-sm6', styles.verticalScrollable)}>
            <DefaultButton
              id="filterButton"
              className={classNames(styles.filterButton)}
              text="Filter"
              iconProps={filterIcon}
              onClick={() => setFilterVisible(true)}
            />
            <div className={styles.tableHeading}>
              <div>
                <Text variant="xLarge" className={styles.highlight}>Registered Vehicles</Text>
                <SeparatorGy vertical />
                <Text variant="xLarge" className={styles.highlight}>{state.total} found</Text>
              </div>
              <div>
                <Text variant="large" className={styles.highlight}>Show # of rows:&nbsp;</Text>
                <Dropdown
                  options={pageSizes.map(pageSize => ({
                    key: pageSize,
                    text: pageSize.toString(),
                  }))}
                  defaultSelectedKey={pageSizes[0]}
                  selectedKey={countOnPage?.key}
                  onChange={onChangeCountOnPage}
                />
              </div>
            </div>
            <div className={styles['table-wrapper']}>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    {
                      columnsState.map(item => (
                        <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    state.items.map((item: any) => (
                      <tr key={item.id}
                        className={classNames(state.selectedItem === item.id ? styles.trSelected : styles.trBasic)}
                      > 
                        <td>
                          <div className={styles.round}>
                            <input
                              type="checkbox"
                              id={item.id}
                              checked={state.selectedItem === item.id}
                              onChange={(e) => handleSelect(e, item.id)}
                            />
                            <label htmlFor={item.id}></label>
                          </div>
                        </td>
                        <td>{item.vehNo}</td>
                        <td>{item.make}</td>
                        <td>{item.model}</td>
                        <td>{item.whlPos}</td>
                        <td>{item.year}</td>
                        <td>{item.status}</td>
                        <td>{item.asOf && moment(item.asOf).format('MM/DD/YYYY')}</td>
                        <td>{item.comments}</td>
                        <td>{item.torque}</td> 
                        <td>{item.lugs}</td>
                        <td>{item.axleFrontPSI}</td>
                        <td>{item.axleCenterPSI}</td>
                        <td>{item.axleRearPSI}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <SeparatorGy />
            <Pagination {...paginationProps} />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm6')}>
            <Text variant="xLarge" block className={styles.highlight}>Vehicle Details</Text>
            <Accordion
              id={accordions.general}
              headerText="General"
              isOpened={openedAccordion === accordions.general}
              onHeaderClick={() => handleAccordionClick(accordions.general)}
            >
              <GeneralRegisterVehicle
                vehicleInfo={getVehicleById(state.selectedItem)}
                handleFetching={fetch}
                openAddingMode={() => setState(prev => ({ ...prev, selectedItem: null }))}
                levelOfService={levelOfService}
              />
            </Accordion>
            {
              state.selectedItem &&
              <>
                <Accordion
                  id={accordions.comments}
                  headerText="Comments"
                  isOpened={openedAccordion === accordions.comments}
                  onHeaderClick={() => handleAccordionClick(accordions.comments)}
                >
                  <div className="ms-Grid">
                    <div className="ms-Grid-row">
                      <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                        <Label htmlFor="comments">
                          <Text className={styles.highlight}>Comments</Text>
                        </Label>
                        <TextField
                          id="comments-field"
                          value={commentsState}
                          onChange={setCommentsValue}
                          disabled={!isNil(getVehicleById(state.selectedItem).status)}
                          multiline
                        />
                      </div>
                    </div>
                    <div className="ms-Grid-row">
                      <div className={classNames('ms-Grid-col', 'ms-sm8')}>
                        <div className="ms-Grid">
                          <div className={classNames('ms-Grid-row', styles.commentsInfo)}>
                            <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                              <Text block className={styles.highlight}>Created On</Text>
                              <Text variant="large" className={styles.highlight}>{moment(getVehicleById(state.selectedItem).createdOn).format('MM/DD/YYYY')}</Text>
                            </div>
                            <div className={classNames('ms-Grid-col', 'ms-sm8')}>
                              <Text block className={styles.highlight}>Last Modified On</Text>
                              <Text variant="large" className={styles.highlight}>{moment(getVehicleById(state.selectedItem).lastModOn).format('MM/DD/YYYY')}</Text>
                            </div>
                          </div>
                          <div className={classNames('ms-Grid-row', styles.commentsInfo)}>
                            <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                              <Text block className={styles.highlight}>Created By</Text>
                              <Text variant="large" className={styles.highlight}>{getVehicleById(state.selectedItem).createdBy}</Text>
                            </div>
                            <div className={classNames('ms-Grid-col', 'ms-sm8')}>
                              <Text block className={styles.highlight}>Last Modified By</Text>
                              <Text variant="large" className={styles.highlight}>{getVehicleById(state.selectedItem).lastModBy}</Text>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={classNames('ms-Grid-col', 'ms-sm4', styles.buttonsWrapper)}>
                        <DefaultButton
                          text="Clear"
                          id="clearCommentsButton"
                          onClick={() => setState(prev => ({ ...prev, selectedItem: null }))}
                          disabled={!userPermissions.isWrite}
                        />
                        <PrimaryButton
                          text="Save"
                          id="saveCommentsButton"
                          disabled={commentsState === get(getVehicleById(state.selectedItem), 'comments', '') || !userPermissions.isWrite}
                          onClick={onSaveComments}
                        />
                      </div>
                    </div>
                  </div>
                </Accordion>
                <Accordion
                  id={accordions.renumber}
                  headerText="Renumber"
                  isOpened={openedAccordion === accordions.renumber}
                  onHeaderClick={() => handleAccordionClick(accordions.renumber)} >
                  <RenumberRegisterVehicle
                    selectedVehicle={getVehicleById(state.selectedItem)}
                    setLoading={(loading: boolean) => setState(prev => ({ ...prev, loading }))}
                    openAddingMode={() => setState(prev => ({ ...prev, selectedItem: null }))}
                    fetchRegisterVehicles={fetch} />
                </Accordion>
                <Accordion
                  id={accordions.relocate}
                  headerText="Relocate"
                  isOpened={openedAccordion === accordions.relocate}
                  onHeaderClick={() => handleAccordionClick(accordions.relocate)}
                >
                  <RelocateRegisterVehicle
                    selectedVehicle={getVehicleById(state.selectedItem)}
                    setLoading={(loading: boolean) => setState(prev => ({ ...prev, loading }))}
                    openAddingMode={() => setState(prev => ({ ...prev, selectedItem: null }))}
                    fetchRegisterVehicles={fetch} />
                </Accordion>
              </>
            }
          </div>
        </div>
      </div>
      <div className="ms-Grid-row">
        <div className={classNames('ms-Grid-col', 'ms-sm12', styles.buttonsWrapper)}>
          <DefaultButton id='printExportBtn' onClick={toggleShowPrintExport} text="Print/Export" />
        </div>
      </div>
      {filterVisible && <FilterModalWindow isOpened={setFilterVisible} filters={filtersState} setFilters={handleUpdateFilters} />}
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default RegisterVehicles;
