import { HeadCell } from '../../../../shared/DataGridComponent/DataGridModels';
import { IFormDataField } from '../../../../shared/FormBuilderComponent';

export interface FootprintMaintainProps {
  value?: string;
}

export interface IFootprint {
  id: string,
  footprintName: string,
  vehicleType: string,
  wheels: string,
  axleSteer: string,
  axleCenter: string,
  axleRear: string,
  imageName: string,
};

export interface IWheelPositions {
  id: string,
  positionName: string,
  description: string,
  seq: string,
};

export interface IAvailableWheelPositions {
  id: string,
  positionName: string,
  description: string,
};


export const pivotItems = {
  footprintDetails: 'Footprint Details',
  wheelPositions: 'Wheel Positions',
};

export const footprintTableHeadCells: HeadCell[] = [
  {
    key: 'column0',
    typeField: 'text',
    isEditable: false,
    name: 'Footprint Name',
    fieldName: 'footprintName',
  },
  {
    key: 'column1',
    typeField: 'text',
    isEditable: false,
    name: 'Vehicle Type',
    fieldName: 'vehicleType',
  },
  {
    key: 'column2',
    typeField: 'text',
    isEditable: false,
    name: 'Wheels',
    fieldName: 'wheels',
  },
  {
    key: 'column3',
    typeField: 'text',
    isEditable: false,
    name: 'Front Axle',
    fieldName: 'axleFront',
  },
  {
    key: 'column4',
    typeField: 'text',
    isEditable: false,
    name: 'Center Axle',
    fieldName: 'axleCenter',
  },
  {
    key: 'column5',
    typeField: 'text',
    isEditable: false,
    name: 'Rear Axle',
    fieldName: 'axleRear',
  },
];

export const wheelPosTableHeadCells: HeadCell[] = [
  {
    key: 'column0',
    typeField: 'text',
    isEditable: false,
    name: 'Position Name',
    fieldName: 'wheelPositionName',
  },
  {
    key: 'column1',
    typeField: 'text',
    isEditable: false,
    name: 'Description',
    fieldName: 'description',
  },
  {
    key: 'column2',
    typeField: 'text',
    isEditable: false,
    name: 'Seq',
    fieldName: 'seq',
  },
];

export const selectWheelPosTableHeadCells: HeadCell[] = [
  {
    key: 'column0',
    typeField: 'text',
    isEditable: false,
    name: 'Position Name',
    fieldName: 'wheelPositionName',
  },
  {
    key: 'column1',
    typeField: 'text',
    isEditable: false,
    name: 'Description',
    fieldName: 'description',
  },
];


export const FOOTPRINT_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'footprintIdField',
    properties: {
      id: 'footprintId',
      label: 'Footprint Id',
      name: 'footprintId',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'footprintNameField',
    properties: {
      id: 'footprintName',
      label: 'Footprint Name',
      name: 'footprintName',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'vehicleTypeField',
    properties: {
      id: 'vehicleType',
      label: 'Vehicle Type',
      name: 'vehicleType',
      type: 'text',
      disabled: false,
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'wheelsField',
    properties: {
      id: 'wheels',
      label: 'No. of Wheel Positions',
      name: 'wheels',
      type: 'number',
      disabled: false,
    },
  },
  {
    formFieldName: 'axleFrontField',
    properties: {
      id: 'axleFront',
      label: 'Axle Front',
      name: 'axleFront',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'axleCenterField',
    properties: {
      id: 'axleCenter',
      label: 'Axle Center',
      name: 'axleCenter',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'axleRearField',
    properties: {
      id: 'axleRear',
      label: 'Axle Rear',
      name: 'axleRear',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'imageName',
    properties: {
      id: 'imageName',
      label: 'Image Name',
      name: 'imageName',
      type: 'text',
      disabled: false,
    },
  },
];

export const WHEELPOS_FIELDS: Array<IFormDataField> = [
  {
    formFieldName: 'wheelPosIdField',
    properties: {
      id: 'wheelPosId',
      label: 'Wheel Position Id',
      name: 'wheelPosId',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'positionNameField',
    properties: {
      id: 'positionName',
      label: 'Position Name',
      name: 'positionName',
      type: 'text',
      disabled: false,
      textTransform: 'uppercase',
    },
  },
  {
    formFieldName: 'descriptionField',
    properties: {
      id: 'description',
      label: 'Description',
      name: 'description',
      type: 'text',
      disabled: false,
    },
  },
  {
    formFieldName: 'seqField',
    properties: {
      id: 'seq',
      label: 'Seq',
      name: 'seq',
      type: 'text',
      disabled: false,
      textTransform: 'uppercase',
    },
  },
];